import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { roles } from '@iso/config/site.config';
import ErrorBoundary from './ErrorBoundary';
import { PUBLIC_ROUTE } from './route.constants';
import Loader from '@iso/components/utility/loader';

const Dashboard = lazy(() => import('./pages/Dashboard/Dashboard'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('@iso/pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import('@iso/pages/404/404')),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import('@iso/pages/500/500')),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('@iso/pages/SignIn/SignIn')),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import('@iso/pages/ResetPassword/ResetPassword')
    ),
  },
  {
      path: PUBLIC_ROUTE.RESET_PASSWORD_CONFIRM,
      component: lazy(() =>
          import('@iso/pages/ResetPasswordConfirm/ResetPasswordConfirm')
     ),
  },
];

const getPermissionsRole = (location, role) => {
  const routePath = location.pathname.split('/')[2] ? location.pathname.split('/')[2] : location.pathname.split('/')[1];
  return roles[role].permissions.includes(routePath) || roles[role].permissions.includes('all');
}

function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  const userRole = useSelector((state) => state.Auth.role);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          getPermissionsRole(location, userRole) ? 
          children : (
            <Redirect
              to={{
                pathname: '/dashboard',
                state: { from: location },
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router basename={process.env.REACT_APP_BASE_DIRECTORY}>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
