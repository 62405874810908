import {zeroPad} from "../../../pages/Invoice/components/helpers";
import {getAccountData} from "./local_storage";

export const setInvoiceNumber = (series, year, increase) => {
    const dataAccount = getAccountData();
    const increasedValue = (increase) ? series.years[year] + 1 : series.years[year];
    const number =  (series.years[year] === undefined) ? 1 : increasedValue;
    return (dataAccount.settings && dataAccount.settings.invoiceNumberWithYear) ?
        year + "/" + zeroPad(number, 4) :
        series.id + "/" + zeroPad(number, 4);
}

export const clearNumberInvoice = (number) => {
    return parseInt(number.replace('/','').replace('#',''));
}
