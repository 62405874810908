const DOCUMENT = "PROPERTY";

const actions = {
  LOAD_PROPERTIES: DOCUMENT + "_LOAD_PROPERTIES",
  LOAD_PROPERTIES_SUCCESS: DOCUMENT + "_LOAD_PROPERTIES_SUCCESS",
  LOAD_PROPERTIES_ERROR: DOCUMENT + "_LOAD_PROPERTIES_ERROR",

  LOAD_PROPERTIES_SELECTOR: DOCUMENT + "_LOAD_PROPERTIES_SELECTOR",
  LOAD_PROPERTY_SELECTOR_SUCCESS: DOCUMENT + "_LOAD_PROPERTY_SELECTOR_SUCCESS",

  LOAD_PROPERTY: DOCUMENT + "_LOAD",
  LOAD_PROPERTY_SUCCESS: DOCUMENT + "_LOAD_SUCCESS",
  LOAD_PROPERTY_ERROR: DOCUMENT + "_LOAD_ERROR",

  SAVE_PROPERTY: DOCUMENT + "_SAVE",
  SAVE_PROPERTY_SUCCESS: DOCUMENT + "_SAVE_SUCCESS",

  UPDATE_PROPERTY: DOCUMENT + "_UPDATE",
  UPDATE_PROPERTY_SUCCESS: DOCUMENT + "_UPDATE_SUCCESS",

  STORE_PROPERTY_ERROR: DOCUMENT + "_SAVE_ERROR",

  UPDATE_STATUS_PROPERTY: DOCUMENT + "_UPDATE_STATUS",
  UPDATE_STATUS_PROPERTY_SUCCESS: DOCUMENT + "_UPDATE_STATUS_SUCCESS",
  UPDATE_STATUS_PROPERTY_ERROR: DOCUMENT + "_UPDATE_STATUS_ERROR",

  SAVE_GALLERY_PROPERTY: DOCUMENT + "_SAVE_GALLERY",
  SAVE_GALLERY_PROPERTY_SUCCESS: DOCUMENT + "_SAVE_GALLERY_SUCCESS",
  SAVE_GALLERY_PROPERTY_ERROR: DOCUMENT + "_SAVE_GALLERY_ERRROR",

  GET_URL_IMAGE_PROPERTY: DOCUMENT + "_GET_URL_IMAGE",
  GET_URL_IMAGE_PROPERTY_SUCCESS: DOCUMENT + "_GET_URL_IMAGE_SUCCESS",
  GET_URL_IMAGE_PROPERTY_ERROR: DOCUMENT + "_GET_URL_IMAGE_ERRROR",

  ADD_REF_GALLERY_TO_PROPERTY: DOCUMENT + "_ADD_REF_GALLERY_TO_PROPERTY",

  DUPLICATE_PROPERTY: DOCUMENT + "_DUPLICATE_PROPERTY",
  DUPLICATE_PROPERTY_SUCCESS: DOCUMENT + "_DUPLICATE_PROPERTY_SUCCESS",
  DUPLICATE_PROPERTY_ERROR: DOCUMENT + "_DUPLICATE_PROPERTY_ERROR",

  DELETE_PROPERTY: DOCUMENT + "_DELETE_PROPERTY",
  DELETE_PROPERTY_SUCCESS: DOCUMENT + "_DELETE_PROPERTY_SUCCESS",
  DELETE_PROPERTY_ERROR: DOCUMENT + "_DELETE_PROPERTY_ERROR",

  REMOVE_NOTIFICATION_MESSAGE: DOCUMENT + "_REMOVE_NOTIFICATION_MESSAGE",

  loadProperties: (filterProperty) => ({
    type: actions.LOAD_PROPERTIES,
    payload: { filterProperty },
  }),

  loadPropertiesSuccess: (data) => ({
    type: actions.LOAD_PROPERTIES_SUCCESS,
    payload: { data },
  }),

  loadPropertiesError: (error) => ({
    type: actions.LOAD_PROPERTIES_ERROR,
    payload: { error },
  }),

  loadProperty: (id) => {
    return {
      type: actions.LOAD_PROPERTY,
      payload: { id },
    };
  },

  loadPropertySuccess: (data) => ({
    type: actions.LOAD_PROPERTY_SUCCESS,
    payload: { data },
  }),

  loadPropertyError: (error) => ({
    type: actions.LOAD_PROPERTY_ERROR,
    payload: { error },
  }),

  loadPropertiesSelector: (filterProperty) => ({
    type: actions.LOAD_PROPERTIES_SELECTOR,
    payload: { filterProperty },
  }),

  loadPropertiesSelectorSuccess: (data) => ({
    type: actions.LOAD_PROPERTY_SELECTOR_SUCCESS,
    payload: { data },
  }),

  updateStatusProperty: (data) => ({
    type: actions.UPDATE_STATUS_PROPERTY,
    payload: { data },
  }),

  updateStatusPropertySuccess: (data) => ({
    type: actions.UPDATE_STATUS_PROPERTY_SUCCESS,
    payload: { data },
  }),

  updateStatusPropertyError: (error) => ({
    type: actions.UPDATE_STATUS_PROPERTY_ERROR,
    payload: { error },
  }),

  saveProperty: (data, actionName = "insert") => ({
    type: actions.SAVE_PROPERTY,
    payload: { data, actionName },
  }),

  savePropertySuccess: () => ({
    type: actions.SAVE_PROPERTY_SUCCESS,
  }),

  storePropertyError: (error) => ({
    type: actions.STORE_PROPERTY_ERROR,
    payload: { error },
  }),

  updateProperty: (data, actionName = "update") => ({
    type: actions.UPDATE_PROPERTY,
    payload: { data, actionName },
  }),

  updatePropertySuccess: () => ({
    type: actions.UPDATE_PROPERTY_SUCCESS,
  }),

  saveGallery: (data) => ({
    type: actions.SAVE_GALLERY_PROPERTY,
    payload: { ...data },
  }),

  saveGallerySuccess: () => ({
    type: actions.SAVE_GALLERY_PROPERTY_SUCCESS,
  }),

  saveGalleryError: (error) => ({
    type: actions.SAVE_GALLERY_PROPERTY_ERROR,
    payload: { error },
  }),

  getCurrentGallery: (data) => ({
    type: actions.GET_URL_IMAGE_PROPERTY,
    payload: { data },
  }),

  loadImageUrlSuccess: (data) => ({
    type: actions.GET_URL_IMAGE_PROPERTY_SUCCESS,
    payload: { data },
  }),

  loadImageyUrlyError: (error) => ({
    type: actions.GET_URL_IMAGE_PROPERTY_ERROR,
    payload: { error },
  }),

  addRefGalleryToProperty: (data, id) => ({
    type: actions.ADD_REF_GALLERY_TO_PROPERTY,
    payload: { data, id },
  }),

  deleteProperty: (data) => ({
    type: actions.DELETE_PROPERTY,
    payload: { data },
  }),

  deletePropertySuccess: () => ({
    type: actions.DELETE_PROPERTY_SUCCESS,
  }),

  deletePropertyError: (error) => ({
    type: actions.DELETE_PROPERTY_ERROR,
    payload: { error },
  }),

  duplicateProperty: (data) => ({
    type: actions.DUPLICATE_PROPERTY,
    payload: { data },
  }),

  duplicatePropertySuccess: () => ({
    type: actions.DUPLICATE_PROPERTY_SUCCESS,
  }),

  duplicatePropertyError: (error) => ({
    type: actions.DUPLICATE_PROPERTY_ERROR,
    payload: { error },
  }),

  cleanNotificationMessage: () => ({
    type: actions.REMOVE_NOTIFICATION_MESSAGE,
  }),
};

export default actions;
