import { combineReducers } from "redux";
import App from "@iso/redux/app/reducer";
import Auth from "@iso/redux/auth/reducer";
import Booking from "@iso/redux/booking/reducer";
import Property from "@iso/redux/property/reducer";
import Albaran from "@iso/redux/albaran/reducer";
import Invoice from "@iso/redux/invoice/reducer";
import Serie from "@iso/redux/serie/reducer";
import Payment from "@iso/redux/payment/reducer";
import Log from "@iso/redux/log/reducer";
import Item from "@iso/redux/item/reducer";
import User from "@iso/redux/user/reducer";
import ThemeSwitcher from "@iso/redux/themeSwitcher/reducer";
import LanguageSwitcher from "@iso/redux/languageSwitcher/reducer";

export default combineReducers({
  Auth,
  App,
  Booking,
  Payment,
  Property,
  Log,
  Item,
  Albaran,
  Serie,
  Invoice,
  User,
  ThemeSwitcher,
  LanguageSwitcher,
});
