import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import omit from 'lodash/omit';
import { simpleQueryFirestoreWithFilter} from '../../components/utility/firestore.helper';
import { rsf } from '@iso/lib/firebase/firebase';
import { getAccountIdPrefix } from '@iso/components/library/helpers/local_storage';

const COLLECTION_NAME = 'invoiceSerie'; // change your collection

function* loadSerieInvoice() {
  try {
    const filter = [
    {
      field: "enabled",
      operator: "==",
      value: true,
    }
    ]
    const data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, filter)
    yield put(actions.loadSerieInvoiceSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(actions.loadSerieInvoiceError(error));
  }
}

function* storeIntoFirestoreSerie({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case 'delete':
        yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${data.key}`, {
          deleted_at: new Date().getTime(),
        });
        break;
      case 'update':
        yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${data.key}`, {
          ...omit(data, ['key']),
        });
        break;
      default:
        yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME, data);
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE });
  } catch (error) {
    console.log(error);
    yield put(actions.saveIntoFireStoreError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadSerieInvoice),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestoreSerie),
  ]);
}
