const DOCUMENT = "USER";

const actions = {
  LOAD_USERS: DOCUMENT + "_LOAD_USERS",
  LOAD_USERS_SUCCESS: DOCUMENT + "_LOAD_USERS_SUCCESS",
  LOAD_USERS_ERROR: DOCUMENT + "_LOAD_USERS_ERROR",

  LOAD_USER: DOCUMENT + "_LOAD_USER",
  LOAD_USER_SUCCESS: DOCUMENT + "_LOAD_USER_SUCCESS",
  LOAD_USER_ERROR: DOCUMENT + "_LOAD_USER_ERROR",

  LOAD_USERS_AUTOCOMPLETE: DOCUMENT + "_LOAD_USERS_AUTOCOMPLETE",
  LOAD_USERS_AUTOCOMPLETE_SUCCESS:
    DOCUMENT + "_LOAD_USERS_AUTOCOMPLETE_SUCCESS",
  LOAD_USERS_AUTOCOMPLETE_ERROR: DOCUMENT + "_LOAD_USERS_AUTOCOMPLETE_ERROR",

  SAVE_USER: DOCUMENT + "_SAVE",
  SAVE_CLIENT: DOCUMENT + "_SAVE_CLIENT",
  SAVE_USER_SUCCESS: DOCUMENT + "_SAVE_USER_SUCCESS",
  SAVE_USER_ERROR: DOCUMENT + "_SAVE_ERROR",

  UPDATE_USER: DOCUMENT + "_UPDATE",
  UPDATE_USER_SUCCESS: DOCUMENT + "_UPDATE_USER_SUCCESS",

  TOGGLE_HANDLE_MODAL_USER: DOCUMENT + "_TOGGLE_USER_HANDLE_MODAL",

  DELETE_USER: DOCUMENT + "_DELETE_USER",
  DELETE_USER_ERROR: DOCUMENT + "_DELETE_USER_ERROR",

  REMOVE_NOTIFICATION_MESSAGE: DOCUMENT + "_REMOVE_NOTIFICATION_MESSAGE",

  getUsers: (filters) => {
    return { type: actions.LOAD_USERS, payload: { filters } };
  },

  getUsersPlatform: (filters, orderBy) => {
    return { type: actions.LOAD_USERS, payload: { filters, orderBy } };
  },

  getUsersSuccess: (data) => ({
    type: actions.LOAD_USERS_SUCCESS,
    payload: { data },
  }),
  getUsersError: (error) => ({
    type: actions.LOAD_USERS_ERROR,
    payload: { error },
  }),
  getUser: (filters) => ({
    type: actions.LOAD_USER,
    payload: { filters }
  }),
  getUserSuccess: (data) => ({
    type: actions.LOAD_USER_SUCCESS,
    payload: { data }
  }),
  getUserError: (error) => ({
    type: actions.LOAD_USER_ERROR,
    payload: { error }
  }),
  loadAutocompleteUsers: (data) => ({
    type: actions.LOAD_USERS_AUTOCOMPLETE,
    payload: { data },
  }),
  getUsersAutocompleteSuccess: (data) => ({
    type: actions.LOAD_USERS_AUTOCOMPLETE_SUCCESS,
    payload: { data },
  }),
  getUsersAutocompleteError: (error) => ({
    type: actions.LOAD_USERS_AUTOCOMPLETE_ERROR,
    payload: { error },
  }),
  saveUser: (data, actionName = "insert", filters, orderBy) => ({
    type: actions.SAVE_USER,
    payload: { data, actionName, filters, orderBy },
  }),
  saveClient: (data, actionName = "insert", filters) => ({
    type: actions.SAVE_CLIENT,
    payload: { data, actionName, filters },
  }),
  saveUserSuccess: () => ({
    type: actions.SAVE_USER_SUCCESS,
  }),

  saveUserError: (error) => ({
    type: actions.SAVE_USER_ERROR,
    payload: { error },
  }),

  updateUser: (data) => ({
    type: actions.UPDATE_USER,
    payload: { data },
  }),
  updateUserSuccess: () => ({
    type: actions.UPDATE_USER_SUCCESS,
  }),
  toggleModal: (data = null) => ({
    type: actions.TOGGLE_HANDLE_MODAL_USER,
    payload: { data },
  }),
  deleteUser: (data, filters) => ({
    type: actions.DELETE_USER,
    payload: { data, filters },
  }),
  deleteUserError: (error) => ({
    type: actions.DELETE_USER_ERROR,
    payload: { error },
  }),
  cleanNotificationMessage: () => ({
    type: actions.REMOVE_NOTIFICATION_MESSAGE,
  }),
};
export default actions;
